import { mapGetters } from 'vuex';

export default {
  name: 'ClinicSelector',
  components: {
  },
  data: function () {
    return {
      selectedClinic: null,
      clinicSearch: ''
    };
  },
  computed: {
    ...mapGetters({
      currentClinic: 'currentClinic',
      allClinics: 'allClinics',
      adminClinics: 'adminClinics'
    })
  },
  methods: {
    initialClinic: function () {
      if (!this.currentClinic?.isAdmin) {
        this.$store.commit('setCurrentClinic', this.adminClinics[0]);
      } else {
        this.selectedClinic = this.currentClinic;
      }
    },
    updateCurrentClinicStore: function () {
      if (this.selectedClinic) {
        this.$refs.clinicSelector.blur();
        this.$store.commit('setCurrentClinic', this.selectedClinic);
      }
    }
  },
  mounted() {
    this.initialClinic();
  },
  watch: {
    currentClinic: function (newVal, oldVal) {
      this.selectedClinic = this.currentClinic;
    },
    clinicSearch: function (newVal, oldVal) {
      if (newVal === null) {
        const clinic = this.currentClinic;
        this.selectedClinic = clinic;
        this.clinicSearch = clinic.clinicName;
      }
    }
  }
};
