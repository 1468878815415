<template>
  <div style="width: 300px;">
    <v-autocomplete
      v-if="adminClinics"
      :disabled="adminClinics.length < 2"
      :items="adminClinics"
      :item-text="'clinicName'"
      :item-value="'clinicId'"
      return-object
      class="mt-2 mb-2 mr-4 clinicSelector"
      v-model="selectedClinic"
      hide-details
      outlined
      dense
      @change="updateCurrentClinicStore"
      :label="'Clinic'"
      height="30px"
      menu-props="bottom, overflowY, offset-y"
      :search-input.sync="clinicSearch"
      ref='clinicSelector'
    ></v-autocomplete>
  </div>
</template>

<script>

import clinicSelectorComponentModel from '@/component-models/clinic-selector-component-model';

export default clinicSelectorComponentModel;

</script>